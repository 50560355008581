import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import screenfull from 'screenfull'; //引入screenfull

export default {
  name: 'Screenfull',
  props: {
    width: {
      type: Number,
      default: 22
    },
    height: {
      type: Number,
      default: 22
    },
    fill: {
      type: String,
      default: '#48576a'
    }
  },
  data: function data() {
    return {
      isShow: true
    };
  },
  mounted: function mounted() {
    if (screenfull.isEnabled) {
      screenfull.on('change', this.changeFullShow);
    }
  },
  methods: {
    click: function click() {
      if (screenfull.isEnabled) {
        screenfull.toggle();
      }
    },
    changeFullShow: function changeFullShow() {
      this.isShow = !screenfull.isFullscreen;
    }
  },
  destroyed: function destroyed() {
    screenfull.off('change', this.changeFullShow);
  }
};